import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  HttpOptions,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  Operation,
  StoreObject,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import isEqual from 'lodash-es/isEqual';

import { Logger } from '@sm/logging/dist/logger';
import { traceClientOperation, traceApolloError } from '@sm/otel-web';
import possibleTypes from '~app/helpers/fragmentTypes';
import config from '~helpers/config';

/**
 * Logger for GraphAPI ApolloErrors
 *
 * _only available server-side_
 */
let logApolloError: Logger | undefined;

/**
 * Logger for Apollo Query performance
 *
 * _only available server-side_
 */
let logApolloPerf: Logger | undefined;
if (process.env.NEXT_RUNTIME === 'nodejs' || process.env.NEXT_RUNTIME === 'edge') {
  // needs to be loaded dynamically to avoid bundling @sm/logging into the client chunk
  // while this might not be available instantly it should not be an issue for graph queries
  void import('@sm/logging').then(logging => {
    logApolloError = logging.getLogger('service-fetch:graphapi:error');
    logApolloPerf = logging.getLogger('service-fetch:graphapi:performance');
  });
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const DEFAULT_TARGET_PATH = 'default';

const DEFAULT_LINK_OPTIONS = {
  credentials: 'include',
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

type CreateApolloClientParams = {
  linkOptions: HttpOptions & {
    batchKey?: Function;
  };
  authLink?: HttpLink;
  availableLoggedOutPaths: string[];
};

const logElapsedMsLink = new ApolloLink((operation, forward) => {
  const start = Date.now();
  const { operationName } = operation;
  return forward(operation).map(result => {
    const end = Date.now();
    const elapsedMs = end - start;
    if (logApolloPerf) {
      // only log on the server side
      logApolloPerf.info({ elapsedMs, operationName }, 'operation-timing');
    }
    return result;
  });
});

const errorLink = onError(error => {
  const {
    networkError,
    graphQLErrors,
    operation: { operationName },
  } = error;
  const errorType = networkError ? 'network' : 'graphql';

  if (process.env.NEXT_RUNTIME === 'client' || !logApolloError) {
    // client side code / fallback
    // eslint-disable-next-line no-console
    console.error(error);
  } else {
    logApolloError.error(
      {
        // @todo: removed as a stop gap due to https://surveymonkey.atlassian.net/browse/PROD-14948
        // err: error,
        networkError,
        graphQLErrors,
        message: `apollo graphql operation ${errorType} error for ${operationName}`,
      },
      'apollo-error'
    );
  }
});

const createApolloClient = ({
  linkOptions = {},
  authLink,
  availableLoggedOutPaths = [],
}: CreateApolloClientParams): ApolloClient<NormalizedCacheObject> => {
  /**
   * TODO:
   * 1. Abstract the following two lines to become a helper / URL/URI constructor
   * 2. Get rid off the hard-coded path
   */
  const ssrMode = typeof window === 'undefined';
  const { headers = {}, ...otherLinkOptions } = linkOptions;
  const apolloLinkMemoizeCache: Record<string, HttpLink> = {};
  function apolloLinkFactory(targetPath = DEFAULT_TARGET_PATH): HttpLink {
    const uri = `${ssrMode ? process.env.GRAPHAPI_HOST : ''}${targetPath}`;
    if (apolloLinkMemoizeCache[targetPath]) {
      return apolloLinkMemoizeCache[targetPath];
    }

    let link = new HttpLink({
      ...DEFAULT_LINK_OPTIONS,
      ...otherLinkOptions,
      uri,
      headers,
    });
    if (authLink && targetPath === '/graphql') {
      link = authLink.concat(link) as HttpLink;
    }
    apolloLinkMemoizeCache[targetPath] = link;
    return link;
  }

  // custom apollo-link that determines the actual link to use based on the passed
  // `targetPath` context.
  const link = new ApolloLink(operation => {
    // if the targetPath is not the default, use an alternate graphql endpoint.
    const targetPath = operation?.getContext?.()?.targetPath;
    let graphQLPath = '/graphql';
    if (availableLoggedOutPaths.includes(targetPath)) {
      // allowed list for security
      graphQLPath = targetPath;
    }

    return apolloLinkFactory(graphQLPath).request(operation) ?? Observable.of();
  });

  return new ApolloClient({
    ssrMode,
    link: from([
      logElapsedMsLink,
      traceClientOperation({ clientName: config.rum.settings.app, appVersion: config.rum.settings.version || '0.0.0' }),
      traceApolloError,
      errorLink,
      link,
    ]),
    cache: new InMemoryCache({
      possibleTypes,
    }),
  });
};

export function initializeApollo(initialState?: StoreObject): ApolloClient<NormalizedCacheObject> {
  const _apolloClient =
    apolloClient ??
    createApolloClient({
      linkOptions: {
        credentials: 'include',
        batchKey: (operation: Operation) => {
          if (operation.getContext().batchName) {
            return operation.getContext().batchName;
          }
          return 'smweb';
        },
      },
      // TODO: move this out to configuration
      availableLoggedOutPaths: [
        '/lo-graphql/collector',
        '/lo-graphql/quizResults',
        '/lo-graphql/enterpriseThankYou',
        '/lo-graphql/surveyTaking',
      ],
    });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge<StoreObject, NormalizedCacheObject>(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

type PageProps = {
  props?: Record<string, unknown>;
};

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, incomingPageProps: PageProps): PageProps {
  const outgoingPageProps = { ...incomingPageProps };
  if (outgoingPageProps?.props) {
    outgoingPageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return outgoingPageProps;
}

export function useApollo(pageProps: NormalizedCacheObject): ApolloClient<NormalizedCacheObject> {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
