// TODO: This needs to be updated whenever we add new INTERFACE types and should be automated in the future
// https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher

// Manual update steps: https://code.corp.surveymonkey.com/thomask/GQLFragmentMatcher

const possibleTypes = {
  SurveyQuestion: [
    'PresentationQuestion',
    'SingleChoiceQuestion',
    'MultipleChoiceQuestion',
    'OpenEndedQuestion',
    'MatrixQuestion',
    'DemographicQuestion',
    'DatetimeQuestion',
    'BasicQuestion',
  ],
  BaseQuestionHeader: ['SurveyQuestionHeader', 'VideoStimuliSurveyQuestionHeader'],
  PaginatedList: [
    'AnalysisStandardViewList',
    'RespondentList',
    'SurveyRespondentResultsList',
    'SurveyQuestionColumnChoiceList',
    'SurveyQuestionList',
    'CollaboratorList',
    'CollectorList',
    'CrossTabReportList',
    'AnalysisResultsDashboardList',
    'SurveyPageList',
    'AnalysisSharedViewList',
    'SurveyQuestionRowList',
    'SurveyQuestionOptionList',
    'WordCloudTagList',
    'SurveyOtherResponseList',
    'TeamImageAssetsList',
    'ApplicationListingList',
    'LibraryAssetsList',
    'SurveyList',
    'AnalysisExportJobList',
    'SurveyTemplateList',
    'SurveyCategoryList',
    'SurveyCategoryIdList',
    'SurveyTemplateCategoryList',
    'SurveyTemplateAttributesList',
    'TeamSurveyList',
    'SurveyQuestionTemplateList',
    'BillingInvoiceList',
    'SurveyQuestionColumnList',
    'NotificationPreferenceList',
  ],
  AnalysisStandardViewFilterRule: [
    'QnAFilter',
    'CollectorFilter',
    'TimePeriodFilter',
    'CompletenessFilter',
    'QuizScoreBucketsFilter',
    'RandomAssignmentFilter',
    'RelativeTimePeriodFilter',
    'ResponseQualityFilter',
    'SinceLastInviteFilter',
    'TagFilter',
  ],
  AnalysisStandardViewCompareRule: ['QuestionAndAnswerCompare', 'RandomAssignmentCompare'],
  SurveyRespondentAnswer: [
    'SurveyChoiceResponse',
    'SurveyImageChoiceResponse',
    'SurveyOtherResponse',
    'SurveyOpenEndedResponse',
    'SurveyMatrixResponse',
    'SurveyMatrixOtherResponse',
    'SurveyMatrixDropDownResponse',
    'SurveyMatrixOpenEndedResponse',
    'SurveyFileUploadResponse',
    'SurveyPaymentResponse',
    'SurveyPresentationImageResponse',
    'SurveyPresentationTextResponse',
  ],
  SurveyQuestionOption: [
    'SurveyQuestionRow',
    'SurveyQuestionOther',
    'SurveyQuestionColumn',
    'SurveyQuestionColumnChoice',
  ],
  Project: ['ConceptTestingProject'],
  ProjectVariableConfiguration: [
    'DefaultConfiguration',
    'ImageStimuliVariableConfiguration',
    'TextStimuliVariableConfiguration',
    'VideoStimuliVariableConfiguration',
    'ProductStimuliVariableConfiguration',
    'NormsVariableConfiguration',
    'AttributesVariableConfiguration',
    'StimuliComparisonQuestionVariableConfiguration',
    'CustomQuestionsBeforeStimuliVariableConfiguration',
    'CustomQuestionsAfterStimuliVariableConfiguration',
  ],
  ProjectVariableAnswer: ['QuestionVariableAnswer', 'QuestionBankVariableAnswer', 'ValueVariableAnswer'],
  ConceptTestingStimulus: ['ConceptTestingTextStimulus', 'ConceptTestingImageStimulus'],
  AttributeQuestion: ['SingleChoiceQuestion', 'MultipleChoiceQuestion', 'OpenEndedQuestion'],
  QuestionResponseSummary: [
    'OneDimensionalResponseSummary',
    'TwoDimensionalResponseSummary',
    'ThreeDimensionalResponseSummary',
  ],
  MultipleComparisonQuestion: ['MultipleChoiceQuestion', 'SingleChoiceQuestion'],
  CustomQuestion: ['SingleChoiceQuestion', 'MultipleChoiceQuestion', 'OpenEndedQuestion'],
  ProjectEntity: ['ProjectAudienceCollectorEntity', 'ProjectSurveyEntity'],
  AudienceTargetingCriteria: [
    'AudienceRangeCriteria',
    'AudienceCategoricalCriteria',
    'AudienceHouseholdIncomeRangeCriteria',
  ],
  AudienceCriteriaSelection: [
    'AudienceCategoricalCriteriaSelection',
    'AudienceRangeCriteriaSelection',
    'AudienceHouseholdIncomeRangeCriteriaSelection',
  ],
  CustomQuestionResponseSummary: ['CustomQuestionAggregateResponseSummary', 'CustomQuestionStimuliResponseSummary'],
  ImageAsset: ['TeamImageAsset'],
  TeamAsset: ['TeamImageAsset'],
  Asset: ['TeamImageAsset'],
  LibraryAsset: ['LibraryImage', 'LibraryTheme', 'LibraryCustomQuestion', 'LibrarySurveyTemplate', 'LibraryDocument'],
  WelcomeFlowQuestion: [
    'WelcomeFlowSingleChoiceQuestion',
    'WelcomeFlowMultipleChoiceQuestion',
    'WelcomeFlowFlagQuestion',
    'WelcomeFlowNumberQuestion',
    'WelcomeFlowTextQuestion',
  ],
  SurveyQuestionTemplate: [
    'BasicQuestionTemplate',
    'SingleChoiceQuestionTemplate',
    'MatrixQuestionTemplate',
    'OpenEndedQuestionTemplate',
    'DemographicQuestionTemplate',
    'MultipleChoiceQuestionTemplate',
    'DatetimeQuestionTemplate',
    'PresentationQuestionTemplate',
  ],
  QuestionVariableAnswerData: [
    'DefaultQuestionVariableAnswerData',
    'ABQuestionVariableAnswerData',
    'VideoABQuestionVariableAnswerData',
  ],
  SurveyQuestionTemplateOption: [
    'SurveyQuestionTemplateRow',
    'SurveyQuestionTemplateOther',
    'SurveyQuestionTemplateColumn',
    'SurveyQuestionTemplateColumnChoice',
  ],
};

export default possibleTypes;
